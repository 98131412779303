import ArrayUtils from '@/ArrayUtils';
import type {Role} from '@/model/role/types';
import * as SharedRoles from '@shared/model/role/default';
import type {ColorStyle as PillColorStyle} from '@/components/core/Pill';

type ClientRole = Role & {
  colorStyle: PillColorStyle;
};

export const StylistRole: ClientRole = {
  ...SharedRoles.StylistRole,
  colorStyle: 'pink',
};

export const OwnerRole: ClientRole = {
  ...SharedRoles.OwnerRole,
  colorStyle: 'indigo',
};

export const ViewerRole: ClientRole = {
  ...SharedRoles.ViewerRole,
  colorStyle: 'gray',
};

export const AllRoles = [StylistRole, OwnerRole, ViewerRole];

export const RolesByPriority = ArrayUtils.ToObjectByProperty(AllRoles, 'priority');
