import ArrayUtils from '@/ArrayUtils';
import type {Role} from '@/model/role/types';

export const StylistRole: Role = {
  priority: 300,
  name: 'Stylist',
  description: 'Stylists can manage everything in an org',
};

export const OwnerRole: Role = {
  priority: 200,
  name: 'Owner',
  description: 'Owners can like, comment, and view published content by Stylists within an org',
};

export const ViewerRole: Role = {
  priority: 100,
  name: 'Viewer',
  description: 'Viewers have read-only access to published content by Stylists within an org',
};

export const AllRoles = [StylistRole, OwnerRole, ViewerRole];

export const RolesByPriority = ArrayUtils.ToObjectByProperty(AllRoles, 'priority');
